@font-face {
    font-family: "The Last Shuriken";
    src: url("../../public/fonts/The_Last_Shuriken.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&display=swap");

.material-symbols-outlined.fill {
    font-variation-settings:
        "FILL" 1,
        "wght" 400,
        "GRAD" 0,
        "opsz" 24;
}

body {
    font-family: "Montserrat";
    background-color: #eef2ff;
}

hr {
    border-top-width: 2px !important;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 18px;
    width: 18px;
    background: #fff;
    border-radius: 50%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

// Scrollbar //
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    min-width: 1px;
    min-height: 1px;
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #888888;
    border-radius: 25px;
}

// Permet de ne pas afficher le scroll si on est pas en hover sur le container
.scrollbar-hover {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
    }

    &:hover {
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #888888;
        }
    }
}

// ANIMATION PULSE //
.animation-pulse {
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.8);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

// GRADIENT ANIMATION //
.gardientAnimation {
    background: linear-gradient(269deg, #5eead4, #14b8a6, #5eead4, #14b8a6);
    background-size: 800% 800%;

    position: relative;
    -webkit-animation: SlideIn 1s ease;
    -moz-animation: SlideIn 1s ease;
    -o-animation: SlideIn 1s ease;
    animation: SlideIn 1s ease;
}

@-webkit-keyframes SlideIn {
    0% {
        top: -50px;
    }
    100% {
        top: 0;
    }
}
@-moz-keyframes SlideIn {
    0% {
        top: -50px;
    }
    100% {
        top: 0;
    }
}
@-o-keyframes SlideIn {
    0% {
        top: -50px;
    }
    100% {
        top: 0;
    }
}
@keyframes SlideIn {
    0% {
        top: -50px;
    }
    100% {
        top: 0;
    }
}

// FLOAT ANIMATION //
.float-animation {
    animation: float 8s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}

// react-flags-select //
.react-flags-select {
    button {
        display: inline;
    }
    ul {
        left: initial;
    }
}

.react-flags {
    & > button {
        height: 34px;
        border-radius: 6px;
    }
}

// react-phone-input-2 //
.react-tel-input {
    .form-control {
        width: 100% !important;
        background-color: transparent !important;
    }
}

// react-pdf //
.react-pdf__Document {
    * {
        // Enable text selection in pdf
        // user-select: text !important;
        // Disable text selection in pdf
        // user-select: all !important;
        // cursor: default !important;
        span {
            pointer-events: none;
        }
    }
    .pdf-cursor-crosshair {
        * {
            cursor: crosshair !important;
        }
    }
}

// react-pdf-viewer
.rpv-core__viewer {
    .pdf-cursor-crosshair {
        * {
            cursor: crosshair !important;
        }
    }
}
.rpv-core__viewer span {
    user-select: none !important;
    pointer-events: none !important;
}
// painterro //
.ptro-holder-wrapper {
    z-index: 100;
}

.ptro-icon-btn {
    border-radius: 27px;
    margin-right: 5px !important;
}

// @cyntler/react-doc-viewer
#react-doc-viewer {
    #proxy-renderer {
        flex: 1;
        #msdoc-renderer {
            height: 100%;
        }
    }
}

// react-render-pdf
.rpv-core__inner-page {
    background-color: transparent !important;
}
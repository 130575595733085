.ant-btn {
    &.ant-btn-icon {
        display: flex;
    }
    .ant-btn-icon {
        display: flex;
        align-items: center;
    }
}

.ant-btn-variant-solid:not(:disabled).ant-btn-color-primary {
    background-color: var(--ant-color-primary) !important;
    &:hover {
        background-color: var(--ant-color-primary-hover) !important;
    }
}

.ant-btn-variant-text {
    font-weight: 500;
}

.ant-card {
    &.ant-card-small {
        border-radius: var(--ant-border-radius);
    }

    &.ant-card-bordered {
        border: 1px solid var(--ant-color-border-secondary);
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        .ant-card-cover {
            border: 1px solid var(--ant-color-border-secondary);
            background-color: white;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            & > * {
                border-top-left-radius: inherit;
                border-top-right-radius: inherit;
            }
        }
    }
    &:not(.ant-card-bordered) {
        border: 1px solid var(--ant-color-border-secondary);
        box-shadow: none;
    }

    &.ant-card-hoverable {
        transition: all 0.2s ease;
        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.25);
        }
        &.card-selected {
            transform: translateY(-4px);
            box-shadow: 0 8px 8px 0 var(--ant-color-primary-bg-hover);
            border: 1px solid var(--ant-color-primary-bg-hover);
            .ant-card-cover {
                border-top: 1px solid var(--ant-color-primary-bg-hover);
                border-left: 1px solid var(--ant-color-primary-bg-hover);
                border-right: 1px solid var(--ant-color-primary-bg-hover);
            }
        }
    }
}

.ant-skeleton-element {
    &.w-full {
        width: 100%;
    }
}

.ant-tag {
    font-weight: 700;
}

.ant-float-btn-icon {
    width: inherit !important;
}
.ant-image-preview-switch-right,
.ant-image-preview-switch-left {
    background-color: #00000022;
}

.ant-dropdown-menu-item {
    @media (max-width: 640px) {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}

.ant-dropdown.options-drawer,
// .ant-select-dropdown.options-drawer,
.ant-dropdown-menu-submenu.options-drawer {
    inset: auto 0px 0px auto !important;
    position: fixed !important;
    width: 100% !important;

    // height: 100%;
    // display: flex;
    // align-items: end;
    // background: rgba(0, 0, 0, 0.2);
    & > * {
        width: 100%;
    }

    .mask {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0);
        opacity: 0;
        position: fixed;
        top: 0;
        animation: fadeIn 0.6s forwards;
        z-index: -1;
    }
}

.ant-dropdown.primary-dropdown {
    ul {
        background-color: var(--ant-color-primary);

        .ant-dropdown-menu-item-group-title {
            color: #bfbfbf;
        }

        .ant-dropdown-menu-item-group-list li span {
            color: #fafafa;
        }

        .ant-dropdown-menu-item-group:not(:last-child) {
            border-bottom: 1px solid #bfbfbf;
            margin-bottom: 10px;
        }
    }
}

.ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: transparent !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

.ant-progress-inner {
    overflow: hidden !important;
}

.ant-image-preview-switch-right,
.ant-image-preview-switch-left {
    background-color: #00000022;
}

.ant-spin-nested-loading {
    &.h-full {
        .ant-spin-container {
            height: 100%;
        }
    }
}

.ant-list-item-meta-avatar {
    margin-top: auto;
    margin-bottom: auto;
}

.ant-modal-title {
    max-width: calc(100% - 30px);
}

.ant-modal-wrap {
    cursor: pointer;
    & > .ant-modal {
        cursor: auto;
    }
}

.ant-menu-item-only-child {
    height: auto !important;
    // padding-left: 12px !important;
}

.arbo {
    .ant-menu-item-selected {
        background-color: transparent !important;
    }
    &.ant-menu-item-selected-drive {
        .ant-menu-submenu-title {
            background-color: var(--ant-menu-dark-item-selected-bg) !important;
        }
    }
}

.ant-collapse-header {
    align-items: center !important;
}

.ant-tabs {
    // Header
    .ant-tabs-tab {
        font-weight: bold;
        padding: var(--ant-tabs-vertical-item-padding) !important;
        &.ant-tabs-tab-active {
            background-color: var(--ant-color-primary-bg);
        }
    }
    // Content
    .ant-tabs-content-holder {
    }
}

.ant-menu-submenu-title {
    padding-right: 0 !important;
}

.ant-breadcrumb {
    ol {
        li {
            display: grid;
        }
    }
}

.ant-collapse-header-text {
    user-select: none;
}

.ant-picker-dropdown {
    .ant-picker-time-panel-column::after {
        height: auto;
    }
}

.ant-form-item-label {
    & > label {
        width: 100%;
    }
}
.ant-form-item-control {
    flex: auto !important;
}

.ant-select-tree-node-content-wrapper {
    // display: flex;
    // align-items: center;
    // width: 100%;
}

.ant-table-cell:last-child:after {
    display: none;
}